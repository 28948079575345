<template>
  <!-- Fixed navbar -->
    <nav class="navbar navbar-default navbar-fixed-top">
      <div class="container">
        <div class="navbar-header">
          <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <a class="navbar-brand" href="/">...</a>
        </div>

        <div id="navbar" class="collapse navbar-collapse">
          <ul class="nav navbar-nav">
            <li><a href="/">Home</a></li>
            <li><a href="/produto">Produto</a></li>
            <li><a href="/marca">Marca</a></li>
          </ul>

          <ul class="nav navbar-nav navbar-right">          
            <li>
              <a href="javascript:void(0);" @click="userLogout">sair ({{ user.name }})</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!--/. End Fixed navbar -->
</template>
<script>

import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  setup() {

    const store = useStore();

    const user = computed(() => {
      if(store.state.auth.user !== null){
       return store.state.auth.user; 
      }
      return {email: null}
    });

    const userLogout = async () => {
      if(window.confirm("Sair?")){
        await store.dispatch('auth/userLogout').then(()=>{
          window.location.reload();
        });
      }
    };

    return {
      user,
      userLogout
    }
    
  },
}
</script>