<template>
    <br />
    <br />
    <br />

    <!-- Begin page footer -->
    <footer class="footer" style="text-align:center;">
      <div class="container">
        <p class="text-muted">...</p>
      </div>
    </footer>
    <!-- End page footer -->
</template>
